import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import AuctionItem, { AUCTION_ITEM_FIELDS } from 'components/common/AuctionItem';
import { useCampaignPage } from 'context/CampaignPage';
import Button from 'components/common/Button';
import Tiles from 'components/common/Tiles';
import CampaignPageSectionHeader from './CampaignPageSectionHeader';

const GET_AUCTION_PREVIEW = gql`
  ${AUCTION_ITEM_FIELDS}
  query GetAuctionPreview($id: String) {
    findCampaigns(id: $id) {
      auction {
        id
        categories(order: "sort") {
          id
          items(order: "sort") {
            ...AuctionItemFields
          }
        }
        uncategorizedItems: items(order: "sort", where: { categoryId: null }) {
          ...AuctionItemFields
        }
      }
    }
  }
`;

const CampaignPageAuctionPreview = ({ title, className }) => {
  const { campaignId } = useCampaignPage();

  const { data } = useQuery(GET_AUCTION_PREVIEW, { variables: { id: campaignId } });
  const categories = useMemo(() => data?.findCampaigns?.[0]?.auction?.categories, [data]);
  const uncategorizedItems = useMemo(
    () => data?.findCampaigns?.[0]?.auction?.uncategorizedItems,
    [data]
  );

  const items = useMemo(() => {
    if (!data) return null;
    if (categories?.length > 0) {
      const categoryItems = categories.reduce((acc, cur) => [...acc, ...cur.items], []);
      return [...categoryItems, ...uncategorizedItems];
    }
    return uncategorizedItems;
  }, [data, categories, uncategorizedItems]);

  if (!items || items.length === 0) return null;

  return (
    <div className={cx('my-24', className)}>
      <div className="sm:container">
        <CampaignPageSectionHeader title={title} titleClassName="text-gray-800" />
        <div className="mt-10">
          <Tiles columns={[2, 2, 4]} spacing="sm" align="center" itemClassName="flex">
            {items.slice(0, 4).map((item) => (
              <AuctionItem key={item.id} {...item} />
            ))}
          </Tiles>
        </div>

        <div className="flex justify-center mt-10">
          <Button
            href={`/c/${campaignId}/auction`}
            radius="full"
            color="primary"
            className="font-medium"
          >
            View all items
            <FontAwesomeIcon icon={faLongArrowRight} className="ml-2" />
          </Button>
        </div>
      </div>
    </div>
  );
};

CampaignPageAuctionPreview.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

CampaignPageAuctionPreview.defaultProps = {
  title: 'Auction',
  className: '',
};

export default CampaignPageAuctionPreview;
